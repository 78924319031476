import { message } from 'antd';

// 通过url获取文件MIME类型
export const getMIME = (val: string) => {
  const index = val.lastIndexOf('.');
  return val.substring(index + 1);
};

// 处理未登录状态
export const handleUnlogin = () => {
  const isLogin = !!$utils._Storage.get('user_userInfo');
  if (!isLogin) {
    message.warn('您尚未登陆，请前往登录');
    return false;
  }
  return true;
};
