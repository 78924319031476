const dataBank = {
  search: '/sys/clCategory/findFileNameList',
  // 一级菜单
  menu1: '/sys/category/findCatCodeList',
  // 二级菜单
  menu2: '/sys/category/findCatPidList',
  // 资料包列表
  source: '/sys/category/findCatPidLevelList',
  // 文件列表
  files: '/sys/clCategory/findFileIdList',
  // 下载次数统计
  downloadCount: '/sys/categoryDown/add',
};

export default dataBank;
