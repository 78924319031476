import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import zhCN from 'antd/es/locale/zh_CN';
import { ConfigProvider } from 'antd';

import App from './App';
import './index.css';
// import reportWebVitals from './reportWebVitals';

import $utils from './utils';
import $http from './http';
import $api from './api';
import { fileService } from './config';
// import tim from '@/setup/tim';

global.$utils = $utils;
global.$http = $http;
global.$api = $api;
global.$fileService = fileService;
// global.$tim = tim;

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <ConfigProvider locale={zhCN}>
      <App />
    </ConfigProvider>
  </BrowserRouter>,
  // </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
