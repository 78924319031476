import Storage from './storage';
import * as common from './common';

const $utils = {
  _Storage: Storage,
  common,
};

export type $Utils = typeof $utils;

export default $utils;
