class BaseStorage {
  private _prefix = 'prefix_';

  constructor(prefix: string) {
    this._prefix = `${prefix}_`;
  }

  private _handleData(data: string | null, key: string) {
    const value = data ? JSON.parse(data) : null;
    if (!value) {
      return null;
    }

    if (value.time) {
      const now = new Date().getTime(); // 当前时间
      if (Number(value.time) < now) {
        localStorage.removeItem(this._prefix + key);
        return null;
      }
      return value.value;
    }
    return value.value;
  }

  /**
   * 存数据
   * @param {string} key 存储名称
   * @param {number} time (默认永久)
   * ```js
   * set(key, data, 10); => 10分钟
   * ```
   */
  set<T = any>(key: string, value: T, time = 0) {
    const duration = +time * 1000 * 60;
    const expirationTime = new Date().getTime() + duration;
    const data = JSON.stringify({
      time: time ? expirationTime : null,
      value,
    });
    localStorage.setItem(this._prefix + key, data);
  }

  /**
   * 获取本地存储
   * @param {string} key 存储名称
   */
  get<T = any>(key: string): T | null {
    const data = localStorage.getItem(this._prefix + key);
    return this._handleData(data, key);
  }

  /**
   * 删除本地存储
   * @param {string} key 存储名称
   */
  remove(key: string) {
    localStorage.removeItem(this._prefix + key);
  }

  /**
   * 清空本地存储
   */
  clear(callback?: () => void) {
    localStorage.clear();
    if (callback) callback();
  }

  private _getAllKeys(): string[] {
    const keys: string[] = [];
    for (let index = 0; index < localStorage.length; index++) {
      keys.push(localStorage.key(index)!);
    }
    return keys;
  }

  /**
   *  移除所有以 prefix 开头的数据
   *  @param {String} prefix 前缀
   *  ```js
   *    removeAll('user'); 移除所有以user开头的本地缓存
   *  ```
   * */
  removeAll(prefix: string) {
    const reg = new RegExp(`^${`${this._prefix}${prefix}`}`);
    const keys = this._getAllKeys().filter(key => reg.test(key));
    keys.forEach(key => {
      localStorage.removeItem(key);
    });
  }
}

export default new BaseStorage('CLTQingLa');
