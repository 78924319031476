import { lazy } from 'react';
import type { RouteConfig } from './type.d';

export const rootRoutes: RouteConfig[] = [
  {
    key: 'DATA_BANK_PAGE',
    path: '/data-bank',
    meta: { title: '资料库' },
    element: lazy(() => import('@/views/data-bank')),
  },
  {
    key: 'RESOURCE_PAGE',
    path: '/resource',
    meta: {},
    element: lazy(() => import('@/views/resource')),
  },
  {
    key: 'QUESTION_PAGE',
    path: '/question',
    meta: { title: '问答' },
    element: lazy(() => import('@/views/question')),
  },
  {
    key: 'HOME_PAGE',
    path: '/',
    meta: { title: '首页' },
    element: lazy(() => import('@/views/home')),
  },
];
