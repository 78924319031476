// 文件服务器地址
export const fileService =
  'http://42.193.255.173:9001/qingla-app/web-images';

export const websiteLocation =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3000'
    : 'http://ql65.cn';

// API服务地址
const dev = 'http://10.10.58.127:3937';
const pro = 'https://app-api.ql65.cn/api/prod';
export const server =
  process.env.NODE_ENV === 'development' ? dev : pro;

// 图片上传接口
export const uploadAction = `${server}/sys/upload/uploadMinio`;

// 腾讯云IM
export const timSDKAppid = 1400779326;
