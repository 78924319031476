import { server } from '@/config';
import _Storage from '@/utils/storage';
import type {
  ConventionDataStructure,
  RequestMethods,
} from './index.d';
import type { Dictionary } from '@/@types/common.d';

export class BaseHttp {
  private server: string;

  constructor(host: string) {
    this.server = host;
  }

  private async _RequestInterceptor() {
    const token =
      (await _Storage.get<string>('user_token')) || 'tourist';
    return {
      'X-Access-Token': token,
    };
  }

  private async _ResponseInterceptor<T>(
    res: Response,
    method: RequestMethods,
  ): Promise<ConventionDataStructure<T>> {
    const { status, url } = res;
    console.log('响应链接 --------------------->', url);
    if (status !== 200) {
      const result: ConventionDataStructure<string> =
        await res.json();
      if (result.message === 'Token失效，请重新登录') {
        await _Storage.removeAll('user');
        const { headers } = res;
        switch (method) {
          case 'GET':
            this.get(url, {}, { ...headers });
            break;
          default:
            break;
        }
      }
      return Promise.reject(result);
    }
    return Promise.resolve(await res.json());
  }

  async get<T>(
    url: string,
    params: Dictionary = {},
    headers?: HeadersInit,
  ) {
    const query = Object.entries(params)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');
    const requestInterceptor = await this._RequestInterceptor();
    const res = await fetch(`${this.server}${url}?${query}`, {
      method: 'GET',
      headers: {
        ...requestInterceptor,
        ...headers,
        'Content-Type': 'application/json',
      },
    });
    return this._ResponseInterceptor<T>(res, 'GET');
  }

  async post<T>(
    url: string,
    params: Dictionary = {},
    headers?: HeadersInit,
  ) {
    const requestInterceptor = await this._RequestInterceptor();
    const res = await fetch(`${this.server}${url}`, {
      method: 'POST',
      body: JSON.stringify(params),
      headers: {
        ...headers,
        'Content-Type': 'application/json',
        ...requestInterceptor,
      },
    });
    return this._ResponseInterceptor<T>(res, 'POST');
  }
}

export default new BaseHttp(server);
