import React, { Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Spin } from 'antd';

import { rootRoutes } from '@/router/root';

import './App.scss';

function LoadingSpin() {
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Spin />
    </div>
  );
}

function App() {
  return (
    <div className="app">
      <Switch>
        <Suspense fallback={<LoadingSpin />}>
          {rootRoutes.map(config => (
            <Route
              key={config.key}
              path={config.path}
              component={config.element}></Route>
          ))}
        </Suspense>
      </Switch>
    </div>
  );
}

export default App;
