const home = {
  // 最新问答列表
  // list: '/user/discuss/findDisList',
  // 推荐关注
  recommend: '/user/recommendFollow/findeRecommendFollow',
  // 更新关注状态
  attentionUpdate: '/user/follow/concerned',
  // 搜索最新问答
  search: '/user/discuss/findDisLike',
  // 最新问答评论
  comments: '/user/discuss/findOneDisList',
  // 最新问答回复
  replies: '/user/discusssecondary/findTwoList',
  // 发送评论
  sendComment: '/user/discussnew/add',
  // 发送回复
  sendReply: '/user/discussnew/add',
  // 获取活动列表
  activity: '/user/activity/findPCActivity',
  // 获取活动详情
  activityDetail: '/user/activity/queryById',
};

export default home;
