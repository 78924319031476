import dataBank from './data-bank';
import home from './home';
import login from './login';
import user from './user';
import question from './question';

const allApi = {
  dataBank,
  home,
  login,
  user,
  question,
};

export default allApi;
